<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="izquierda">
        <h2>Carga de Proveedores</h2>
      </v-col>
      <v-col cols="6" class="derecha"> </v-col>
      <v-col cols="12" class="centrado">
        <iframe
          width="560"
          height="315"
          src="https://youtu.be/Vk07cXLM2vo?si=WGbmXc9wTw5Biows"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </v-col>
      <v-col cols="5" offset="4">
        <v-row>
          <v-col cols="4">
            <v-btn color="#2C3AB8" @click="descargarFormato()" dark
              >Descargar plantilla</v-btn
            >
          </v-col>

          <v-col cols="4">
            <v-btn color="#A4A6B5" dark @click="openFileInput()"
              >Cargar Datos</v-btn
            >
          </v-col>
          <v-col cols="4">
            <v-btn color="#A4A6B5" @click="omitir()" dark>Omitir</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-file-input
          v-model="files"
          id="fileInput"
          style="display: none"
          @change="handleFileChange"
          accept=".xlsx, .xls"
        />
      </v-col>
      <v-col cols="12" class="centrado" v-if="mostrarGuardar">
        <v-btn
          color="#2C3AB8"
          :loading="loading"
          dark
          @click="continuarLlenadoPasoClientes()"
        >
          Guardar y Continuar
        </v-btn>
      </v-col>
      <v-col cols="12" class="centrado" v-if="mostrarGuardar">
        <div class="col-12">
          <h3>Listado de Proveedores</h3>
        </div>
        <div class="col-12 classTableProveedor">
          <v-data-table
            :headers="headers"
            :items="$store.state.configEmpresa.lstProveedor"
            pagination.sync="pagination"
            item-key="index"
            class="elevation-5"
          >
            <template v-slot:[`item.action`]="{ item, index }">
              <v-btn icon color="red" @click="eliminar(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { mapActions } from "vuex/dist/vuex.common.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      files: [],
      headers: [
        { text: "Acción", value: "action" },
        { text: "TAX ID / RUC /  VAT / RIF", value: "nro_documento" },
        { text: "Razón social/Nombre Comercial", value: "nombre_comercial" },
        { text: "Email", value: "email" },
        { text: "Persona Contacto 1", value: "contacto_1" },
        { text: "Teléfono Contacto 1", value: "contacto_telf_1" },
        { text: "Persona Contacto 2", value: "contacto_2" },
        { text: "Teléfono Contacto 2", value: "contacto_telf_2" },
        { text: "Dirección", value: "direccion" },
        { text: "Teléfono adicional 1", value: "telefono_1" },
        { text: "Teléfono adicional 2", value: "telefono_2" },
      ],
      mostrarGuardar: false,
      index_nombre_comercial: 0,
      index_nro_documento: 1,
      index_contacto_1: 2,
      index_contacto_telf_1: 3,
      index_contacto_2: 4,
      index_contacto_telf_2: 5,
      index_direccion: 6,
      index_telefono_1: 7,
      index_telefono_2: 8,
      index_email: 9,
      loading: false,
      subconjuntos: [],
    };
  },
  methods: {
    ...mapActions(["actualizarDatoCMProveedor"]),
    omitir() {
      this.$store.state.enterprises.datoProveedorFlag = false;
      this.$store.state.enterprises.datoClienteFlag = true;
    },
    async continuarLlenadoPasoClientes() {
      this.loading = true;
      // this.dividirEnSubconjuntos();
      for (const subconjunto of this.dividirEnSubconjuntos()) {
        await this.actualizarDatoCMProveedor(subconjunto);
      }
      Swal.fire({
        icon: "success",
        text: "Registro Correcto",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      this.$store.state.enterprises.datoProveedorFlag = false;
      this.$store.state.enterprises.datoClienteFlag = true;
      this.loading = false;
    },
    descargarFormato() {
      window.open(
        "https://api-general.qreport.site/uploads/Formato Proveedor.xlsx",
        "_blank"
      );
    },
    dividirEnSubconjuntos() {
      const SUBCONJUNTO_TAMANO = 100;
      const subconjuntos = [];
      for (
        let i = 0;
        i < this.$store.state.configEmpresa.lstProveedor.length;
        i += SUBCONJUNTO_TAMANO
      ) {
        subconjuntos.push(
          this.$store.state.configEmpresa.lstProveedor.slice(
            i,
            i + SUBCONJUNTO_TAMANO
          )
        );
      }
      return subconjuntos;
    },
    openFileInput() {
      let fileInput = document.getElementById("fileInput");
      if (fileInput) {
        this.files = [];
        fileInput.click();
      }
      //   document.getElementById("fileInput").click();
    },
    handleFileChange() {
      this.$store.state.configEmpresa.lstProveedor = [];
      readXlsxFile(this.files).then((rows) => {
        this.procesarDatosProveedor(rows);
      });
      this.mostrarGuardar = true;
      this.files = [];
    },
    procesarDatosProveedor(rows) {
      for (let index = 0; index < rows[0].length; index++) {
        if (rows[0][index] == "nombre_comercial") {
          this.index_nombre_comercial = index;
        }
        if (rows[0][index] == "nro_documento") {
          this.index_nro_documento = index;
        }
        if (rows[0][index] == "contacto_1") {
          this.index_contacto_1 = index;
        }
        if (rows[0][index] == "contacto_telf_1") {
          this.index_contacto_telf_1 = index;
        }
        if (rows[0][index] == "contacto_2") {
          this.index_contacto_2 = index;
        }
        if (rows[0][index] == "contacto_telf_2") {
          this.index_contacto_telf_2 = index;
        }
        if (rows[0][index] == "direccion") {
          this.index_direccion = index;
        }
        if (rows[0][index] == "telefono_1") {
          this.index_telefono_1 = index;
        }
        if (rows[0][index] == "telefono_2") {
          this.index_telefono_2 = index;
        }

        if (rows[0][index] == "email") {
          this.index_email = index;
        }
      }

      for (let index = 1; index < rows.length; index++) {
        this.$store.state.configEmpresa.lstProveedor.push({
          nombre_comercial: rows[index][this.index_nombre_comercial],
          nro_documento: rows[index][this.index_nro_documento],
          contacto_1: rows[index][this.index_contacto_1],
          contacto_telf_1: rows[index][this.index_contacto_telf_1],
          contacto_2: rows[index][this.index_contacto_2],
          contacto_telf_2: rows[index][this.index_contacto_telf_2],
          direccion: rows[index][this.index_direccion],
          telefono_1: rows[index][this.index_telefono_1],
          telefono_2: rows[index][this.index_telefono_2],
          email: rows[index][this.index_email],
        });
      }
      this.files = [];
    },
    eliminar(index) {
      this.$store.state.configEmpresa.lstProveedor.splice(index, 1);
    },
  },
  mounted() {
    // console.log("recargo");
    this.ids_modality = this.$store.state.pricing.listModality.map(
      (element) => {
        return element.id;
      }
    );
    this.ids_shipment = this.$store.state.pricing.listShipment.map(
      (element) => {
        return element.id;
      }
    );
    this.id_modality = this.ids_modality[0];
    this.id_shipment = this.ids_shipment[0];
  },
};
</script>

<style scoped>
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.logo {
  max-width: 25%;
  background: brown;
}
.blue {
  background: none !important;
  color: #09305e !important;
}
.classTableProveedor {
  margin: 0 0 20% 0;
}
/* .mt-info {
    padding: 0% 5%;
    margin: 25% 0 0 0;
  } */
</style>
